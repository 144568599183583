exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-upgrade-js": () => import("./../../../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */),
  "component---src-templates-all-questions-js": () => import("./../../../src/templates/allQuestions.js" /* webpackChunkName: "component---src-templates-all-questions-js" */),
  "component---src-templates-country-index-page-js": () => import("./../../../src/templates/countryIndexPage.js" /* webpackChunkName: "component---src-templates-country-index-page-js" */),
  "component---src-templates-driving-schools-per-country-js": () => import("./../../../src/templates/drivingSchools/perCountry.js" /* webpackChunkName: "component---src-templates-driving-schools-per-country-js" */),
  "component---src-templates-driving-schools-per-region-js": () => import("./../../../src/templates/drivingSchools/perRegion.js" /* webpackChunkName: "component---src-templates-driving-schools-per-region-js" */),
  "component---src-templates-question-js": () => import("./../../../src/templates/question.js" /* webpackChunkName: "component---src-templates-question-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

